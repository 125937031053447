import PropTypes from "prop-types";
import React from "react";
import startLogo from "../images/start-header.png";
import endLogo from "../images/end-header.png";

const Header = ({ siteTitle }) => (
  <div className="site-header-wrapper">
    <div
      style={{
        justifyContent: "space-between",
        display: "flex",
        maxWidth: "1023px",
        margin: "auto",
      }}
    >
      <div>
        <a href="/">
          <div className="site-header-content">
            <img
              className="hideOnSmallScreens"
              src={startLogo}
              alt="Logo Start"
              height="30"
            ></img>
            <div className="site-header-text">{siteTitle}</div>
            <img
              className="hideOnSmallScreens"
              src={endLogo}
              alt="Logo End"
              height="30"
            ></img>
          </div>
        </a>
      </div>
      <div>
        <a href="/about">
          <div className="site-header-content">
            <button className="button">
              <div className="button-text">About</div>
            </button>
          </div>
        </a>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
