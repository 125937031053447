import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import CommentContainer from "./comment-container.js";

import Header from "./header";
import "./layout.css";

const Layout = ({ hideComments, location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  const [modalContent, setModalContent] = React.useState(0); //setState

  const print = function () {
    window.print();
  };

  const showModal = function (content) {
    let modalElement = document.getElementById("modal");
    setModalContent(<div>{content}</div>);
    modalElement.classList.add("show-modal");
  };

  const closeModal = function () {
    let modalElement = document.getElementById("modal");
    modalElement.classList.remove("show-modal");
  };

  /*
  this.changeBackgroundColor = function (newColor) {
    let elements = document.getElementsByClassName("daylight");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = newColor;
    }
  };
  */

  // When running gatsby build, Node is used (and the window object isn't present), this fixes that issue
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    return <div> loading... </div>;
  }

  let comments = (
    <CommentContainer
      pageUrl={window.location.pathname.replace(/^\/|\/$/g, "")}
      showModal={showModal}
      closeModal={closeModal}
    ></CommentContainer>
  );

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto"
        rel="stylesheet"
      ></link>
      <Header siteTitle={data.site.siteMetadata.title} />

      <div id="modal" className="modal" onClick={closeModal.bind(this)}>
        <div className="modal-content">
          <div>{modalContent}</div>
        </div>
      </div>

      <div className="main-content">
        <main>{children}</main>
      </div>

      {hideComments ? undefined : comments}

      <div className="outer footer-wrap no-print">
        <footer className="inner footer-content">
          <div>
            <a
              href={`mailto:?Subject=Sharable%20Page%20from%20Endless%20WIPS&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20 ${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="Email"
                src="https://live.staticflickr.com/65535/50543892693_8abcacb9c2_o.png[/img][/url][url=https://flic.kr/p/2k1oEnK"
              />
            </a>
            <a
              href={`http://www.facebook.com/sharer.php?u=${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="Facebook"
                src="https://live.staticflickr.com/65535/50544759302_45628871e6_o.png"
              />
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="LinkedIn"
                src="https://live.staticflickr.com/65535/50544759287_33ed3aea90_o.png"
              />
            </a>
            <a
              href={`http://pinterest.com/pin/create/button/?url=${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="Pinterest"
                src="https://live.staticflickr.com/65535/50544617596_d00645c6d8_o.png"
              />
            </a>
            <button style={{ cursor: "pointer" }} onClick={print}>
              <img
                className="share-button"
                alt="Print"
                src="https://live.staticflickr.com/65535/50544759257_a6af321547_o.png"
              />
            </button>
            <a
              href={`http://reddit.com/submit?url=${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="Reddit"
                src="https://live.staticflickr.com/65535/50543892613_21a716da3a_o.png"
              />
            </a>
            <a
              href={`https://twitter.com/share?url=${data.site.siteMetadata.siteUrl}${location.pathname}`}
            >
              <img
                className="share-button"
                alt="Twitter"
                src={`https://live.staticflickr.com/65535/50544759227_5b8368069c_o.png`}
              />
            </a>
            <div className="copyright">
              ©Endlesswips.com All Rights Reserved |{" "}
              <a href="/about/#disclaimer">Disclaimer</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
