import React from "react";

import CommentSubmit from "./comment-submit.js";
import CommentDisplay from "./comment-display.js";
import { Component } from "react";
import Spinner from "./spinner";

import Utils from "../helpers/utils";

class CommentContainer extends Component {
  constructor(props) {
    super(props);
    this.KEY_LENGTH = 20;

    this.state = {
      commentData: false,
      replyId: false,
      admin: false,
    };
  }

  async fetchComments() {
    let reply = {};
    let response = undefined;
    try {
      response = await fetch(
        `https://storage.googleapis.com/endlesswips-comments-content/${this.props.pageUrl}`
      );
      reply = await response.json();
    } catch (e) {
      console.warn(`Error getting comments for ${this.props.pageUrl}: `, e);
      //response.text().then(v => {
      //  console.log(v);
      //});
    }
    this.setState({ commentData: reply });
  }

  async loadComments(entries) {
    if (
      entries[0].isIntersecting === true &&
      this.state.commentData === false
    ) {
      console.log("Element is visible in screen - loading comments");
      this.fetchComments.call(this);
    }
  }

  showReplySubmitSection(id) {
    return function () {
      this.setState({ replyId: id });
    }.bind(this);
  }

  async componentDidMount() {
    let observer = new IntersectionObserver(this.loadComments.bind(this), {
      threshold: [0.1],
    });

    let commentContainer = document.querySelector(`#comment-container`); // TODO: this only allows 1 instance of the component per page, that okay?
    if (commentContainer) {
      observer.observe(commentContainer);
    }

    // Admin mode
    window.admin = function () {
      console.log("Enabling admin mode");
      this.setState({ admin: true });
    }.bind(this);
  }

  addCommentsToArray(previousComments, commentsObject, level) {
    if (Utils.isEmpty(commentsObject)) {
      return previousComments;
    }
    let toReturn = previousComments.slice(0); // make a copy
    for (let commentId in commentsObject) {
      let comment = commentsObject[commentId];
      toReturn.push(
        <CommentDisplay
          key={commentId}
          referenceId={commentId}
          comment={comment.comment}
          //website={comment.website}
          name={comment.name}
          postId={this.props.pageUrl}
          emailHash={comment.email}
          padding={40 * level}
          timestamp={comment.timestamp}
          onReplyClick={this.showReplySubmitSection.call(this, commentId)}
          admin={this.state.admin}
          showModal={this.props.showModal}
          closeModal={this.props.closeModal}
          reloadComments={this.fetchComments.bind(this)}
        ></CommentDisplay>
      );
      if (this.state.replyId === commentId) {
        let postId = this.props.pageUrl;
        toReturn.push(
          <div
            style={{
              marginBottom: "-20px",
              padding: "5px",
              paddingLeft: "22px",
              fontSize: "11pt",
              color: "darkgray",
            }}
          >
            Replying to {comment.name}
          </div>
        );
        toReturn.push(
          <CommentSubmit
            replyTo={commentId}
            postId={postId}
            reloadComments={this.fetchComments.bind(this)}
            showModal={this.props.showModal}
            closeModal={this.props.closeModal}
            padding={40 * level}
            clearReplyClick={this.showReplySubmitSection.call(this, false)}
          ></CommentSubmit>
        );
      }

      toReturn = this.addCommentsToArray(toReturn, comment.replies, level + 1);
    }
    return toReturn;
  }

  render() {
    // Don't show the comments section for the main page
    if (!this.props.pageUrl) {
      return null;
    }

    let comments = (
      <div style={{ margin: "auto", width: "13%", padding: "20px" }}>
        <Spinner></Spinner>
      </div>
    );
    if (this.state.commentData) {
      comments = this.addCommentsToArray([], this.state.commentData, 0);
    }

    let postId = this.props.pageUrl;

    return (
      <div
        id="comment-container"
        className="inner-content no-print"
        style={{ paddingLeft: "10px", overflow: "auto", marginBottom: "14px" }}
      >
        <hr className="solid"></hr>
        <div style={{ fontWeight: 700, fontSize: "32px" }}> Comments</div>

        {comments}
        <CommentSubmit
          postId={postId}
          reloadComments={this.fetchComments.bind(this)}
          showModal={this.props.showModal}
          closeModal={this.props.closeModal}
          clearReplyClick={function () {}}
        ></CommentSubmit>
      </div>
    );
  }
}
export default CommentContainer;
