class Utils {
  static getArrayOfLength(length, value) {
    let array = [];
    for (let i = 0; i < length; i++) {
      array.push(i * value);
    }
    return array;
  }

  static mean(numbers) {
    return numbers.reduce((a, b) => a + b) / numbers.length;
  }

  static median(numbers) {
    const middle = (numbers.length + 1) / 2;
    const sorted = [...numbers].sort((a, b) => a - b);
    const isEven = sorted.length % 2 === 0;
    return isEven
      ? (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2
      : sorted[middle - 1];
  }

  static CAGR(beginingValue, endingValue, periods) {
    return Math.pow(endingValue / beginingValue, 1 / periods) - 1;
  }

  /**
   * This IRR method works better than it's financejs counterpart for our purposes.
   * From: https://gist.github.com/ghalimi/4591338
   */
  static IRR(values, guess) {
    // Credits: algorithm inspired by Apache OpenOffice

    // Calculates the resulting amount
    var irrResult = function (values, dates, rate) {
      var r = rate + 1;
      var result = values[0];
      for (var i = 1; i < values.length; i++) {
        result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
      }
      return result;
    };

    // Calculates the first derivation
    var irrResultDeriv = function (values, dates, rate) {
      var r = rate + 1;
      var result = 0;
      for (var i = 1; i < values.length; i++) {
        var frac = (dates[i] - dates[0]) / 365;
        result -= (frac * values[i]) / Math.pow(r, frac + 1);
      }
      return result;
    };

    // Initialize dates and check that values contains at least one positive value and one negative value
    var dates = [];
    var positive = false;
    var negative = false;
    for (var i = 0; i < values.length; i++) {
      dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
      if (values[i] > 0) positive = true;
      if (values[i] < 0) negative = true;
    }

    // Return error if values does not contain at least one positive value and one negative value
    if (!positive || !negative) return "#NUM!";

    // Initialize guess and resultRate
    var guess = typeof guess === "undefined" ? 0.1 : guess;
    var resultRate = guess;

    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10;

    // Set maximum number of iterations
    var iterMax = 50;

    // Implement Newton's method
    var newRate, epsRate, resultValue;
    var iteration = 0;
    var contLoop = true;
    do {
      resultValue = irrResult(values, dates, resultRate);
      newRate =
        resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
      epsRate = Math.abs(newRate - resultRate);
      resultRate = newRate;
      contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
    } while (contLoop && ++iteration < iterMax);

    if (contLoop) return "#NUM!";

    // Return internal rate of return
    return resultRate;
  }

  /*
  static base64ToHex(base64) {
    let buffer = Buffer.from(base64, "base64");
    return buffer.toString("hex");
  }*/

  static base64ToHex(str) {
    try {
      // Make sure we are not using the url-save base64
      str = Utils.invMakeBase64UrlSafe(str);
      const raw = atob(str);
      let result = "";
      for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += hex.length === 2 ? hex : "0" + hex;
      }
      return result.toUpperCase();
    } catch (e) {
      console.log("Can't atob ", str);
    }
  }

  static hexToBinary(hex) {
    hex = hex.replace("0x", "").toLowerCase();
    var out = "";
    for (var c of hex) {
      switch (c) {
        case "0":
          out += "0000";
          break;
        case "1":
          out += "0001";
          break;
        case "2":
          out += "0010";
          break;
        case "3":
          out += "0011";
          break;
        case "4":
          out += "0100";
          break;
        case "5":
          out += "0101";
          break;
        case "6":
          out += "0110";
          break;
        case "7":
          out += "0111";
          break;
        case "8":
          out += "1000";
          break;
        case "9":
          out += "1001";
          break;
        case "a":
          out += "1010";
          break;
        case "b":
          out += "1011";
          break;
        case "c":
          out += "1100";
          break;
        case "d":
          out += "1101";
          break;
        case "e":
          out += "1110";
          break;
        case "f":
          out += "1111";
          break;
        default:
          return "";
      }
    }

    return out;
  }

  /*
  static sendRequest(options, body) {
    return new Promise((resolve, reject) => {
      const req = https.request(options, res => {
        const data = [];
        res.on("data", chunk => {
          data.push(chunk);
        });

        res.on("end", () => {
          let allData = Buffer.concat(data).toString();
          //console.log(allData);
          if (res.statusCode < 200 || res.statusCode >= 300) {
            console.log("statusCode:", res.statusCode);
            console.log("message", Buffer.concat(data).toString());
            return reject(new Error(`Status Code: ${res.statusCode}`));
          } else {
            return resolve(allData);
          }
        });
      });
      req.on("error", reject);
      if (body) {
        req.write(JSON.stringify(body));
      }
      req.end();
    });
  }*/

  static isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  // https://stackoverflow.com/questions/13694626/generating-random-numbers-0-to-1-with-crypto-generatevalues
  static cryptoRandom() {
    let arr = new Uint32Array(1);
    crypto.getRandomValues(arr);
    return arr[0] * Math.pow(2, -32);
  }

  static secondsToFormattedTimeString(seconds, precision) {
    if (isNaN(seconds)) {
      return "0 seconds";
    }

    if (seconds === 0) {
      return "0 seconds";
    }

    // Display all available numbers if a smaller number isn't specified
    if (precision === 0) {
      precision = 5;
    }

    let numYears;
    let numDays;
    let numHours;
    let numMinutes;
    let numSeconds;
    if (seconds > 0) {
      numYears = Math.floor(seconds / 31536000);
      numDays = Math.floor((seconds % 31536000) / 86400);
      numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
      numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    } else {
      numYears = Math.ceil(seconds / 31536000);
      numDays = Math.ceil((seconds % 31536000) / 86400);
      numHours = Math.ceil(((seconds % 31536000) % 86400) / 3600);
      numMinutes = Math.ceil((((seconds % 31536000) % 86400) % 3600) / 60);
      numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    }

    return (
      (numYears == 0 ? "" : numYears + " years ") +
      (numDays == 0 || precision < 1 ? "" : numDays + " days ") +
      (numHours == 0 || precision < 2 ? "" : numHours + " hours ") +
      (numMinutes == 0 || precision < 3 ? "" : numMinutes + " minutes") +
      (numSeconds == 0 || precision < 4
        ? ""
        : numSeconds.toFixed(0) + " seconds")
    );
  }

  static getRandomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static round(toRound, decimalPlaces) {
    decimalPlaces = decimalPlaces ? decimalPlaces : 0;
    return (
      Math.round(toRound * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces)
    );
  }

  static formatPercentage(value, decimalPlaces) {
    decimalPlaces = decimalPlaces ? decimalPlaces : 0;
    if (isNaN(value)) {
      return "-%";
    }
    return (parseFloat(value) * 100).toFixed(decimalPlaces) + "%";
  }

  static formatMoney(value) {
    if (isNaN(value)) {
      return "-";
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let formattedMoney = formatter.format(value);
    if (formattedMoney.slice(-3) === ".00") {
      return formattedMoney.slice(0, -3);
    }
    return formattedMoney;
  }

  static async textFetch(httpVerb, url, body) {
    try {
      let response = await fetch(`${url}`);
      return await response.text();
    } catch (e) {
      console.warn(`Error during fetch for ${url}: `, e);
      return null;
      //response.text().then(v => {
      //  console.log(v);
      //});
    }
  }

  static standardDeviation(array) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(
      array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  /**
   * Turns csv text file into a json object that contains an entry for each row of the csv.
   * The key for each entry is determined the the value in the keyIndex column.
   *
   * Assumes the values in the keyIndex column are unique, if not, values will be overridden
   */
  static csvToJsonObject(csvText, keyIndex) {
    let result = {};
    let headers = [];
    let allCsvRows = csvText.split(/\r?\n/);
    for (let csvRowIndex in allCsvRows) {
      let csvRow = allCsvRows[csvRowIndex];

      // The first row in the csv contains headers - this determines the keys for each value in the json
      if (parseInt(csvRowIndex) === 0) {
        headers = csvRow.split(",");
        continue;
      }

      // All the other rows should result in an entry into the json object
      let allRowValues = csvRow.split(",");
      let entryKey = allRowValues[keyIndex];
      let entry = {};
      for (let rowValueIndex in allRowValues) {
        if (rowValueIndex === keyIndex) {
          // Skip this one, it's the key
        } else {
          let key = headers[rowValueIndex];
          let value =
            allRowValues[rowValueIndex].trim() === ""
              ? undefined
              : allRowValues[rowValueIndex];
          entry[key] = value;
        }
      }

      // Add the data from the csv row to the object
      result[entryKey] = entry;
    }

    return result;
  }

  static percentIncrease(a, b) {
    let percent;
    if (b !== 0) {
      if (a !== 0) {
        percent = (b - a) / a;
      } else {
        percent = b;
      }
    } else {
      percent = -a;
    }
    return percent;
  }

  static getDaysBetween(startDate, endDate) {
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);
    let diffMs = date2.getTime() - date1.getTime();
    return diffMs / (1000 * 3600 * 24);
  }

  static formatDate(date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  static getQueryObj() {
    let queryString = window.location.search || "";
    if (queryString[0] === "?") {
      queryString = queryString.slice(1);
    }
    let params = {},
      queries,
      temp,
      i,
      l;
    queries = queryString.split("&");
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=");
      params[temp[0]] = temp[1];
    }
    return params;
  }

  static makeBase64UrlSafe(input) {
    input = input.replaceAll("+", "-");
    input = input.replaceAll("/", "_");
    input = input.replaceAll("=", "");
    return input;
  }

  static invMakeBase64UrlSafe(input) {
    input = input.replaceAll("-", "+");
    input = input.replaceAll("_", "/");
    //input = input.replaceAll("=", "");
    return input;
  }

  static editQueryObject(fieldName, value) {
    let queryObject = this.getQueryObj();
    queryObject[fieldName] = value;
    let queryString = "";
    let keys = Object.keys(queryObject);
    let separationChar = "?";
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] && queryObject[keys[i]]) {
        queryString =
          queryString + separationChar + keys[i] + "=" + queryObject[keys[i]];
        separationChar = "&";
      }
    }
    window.history.replaceState(
      {},
      "",
      window.location.origin + window.location.pathname + queryString
    );
  }

  static generateNormalMarketData(mean, sigma, length, keys) {
    let result = {};
    for (let i = 0; i < length; i++) {
      result[keys[i]] = { return: this.randomFromNormal(mean, sigma) };
    }
    return result;
  }

  static randomFromNormal(mean, sigma) {
    // https://stackoverflow.com/questions/25582882/javascript-math-random-normal-distribution-gaussian-bell-curve
    let u = 1 - Math.random(); //Converting [0,1) to (0,1)
    let v = Math.random();
    let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    // Transform to the desired mean and standard deviation:
    return z * sigma + mean;
  }
}

module.exports = Utils;
