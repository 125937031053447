import React from "react";

import "./spinner.css";

class Spinner extends React.Component {
  render() {
    return (
      <div id="loading" style={this.props.style}>
        <div className="loading-spinner">
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
          <div
            className={`spinner-dot ${
              this.props.inverted ? "spinner-white" : "spinner-red"
            }`}
          ></div>
        </div>
      </div>
    );
  }
}

export default Spinner;
