import React from "react";

import { Component } from "react";

import Spinner from "./spinner";

import Utils from "../helpers/utils";

import CommentDisplay from "./comment-display.js";

class CommentSubmit extends Component {
  state = { submitting: false };

  constructor(props) {
    super(props);
    this.commentSubmitForm = React.createRef();
  }

  async handleSubmit(event) {
    let formElement = this.commentSubmitForm.current;
    const formEntries = new FormData(formElement).entries();
    const formJsonData = Object.assign(
      ...Array.from(formEntries, ([x, y]) => ({ [x]: y }))
    );
    console.log("CLICKED", formJsonData);
    event.preventDefault();
    let response = {};
    let reply = [];
    try {
      this.setState({ submitting: true });
      response = await fetch(
        "https://us-central1-decisive-design-307818.cloudfunctions.net/post-comment",
        {
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formJsonData),
        }
      );
      reply = await response.json();
      console.log(reply);

      // Only reset if successful
      if (response.status === 200) {
        console.log("Resetting form", this.props);
        this.props.clearReplyClick();
        formElement.reset();
        this.props.showModal(
          <div className="modal-text">
            <div>
              <b>✅ Your comment has been posted successfully!</b>
            </div>
            <div style={{ textAlign: "center", paddingTop: "7px" }}>
              <button className="button" onClick={this.props.closeModal}>
                Okay
              </button>
            </div>
          </div>
        );
      } else {
        this.props.showModal(
          <div className="modal-text">
            <div>
              <b>
                ❌ There was an error while attempting to post your comment:{" "}
              </b>
              {reply.message}
            </div>
            <div style={{ textAlign: "center", paddingTop: "7px" }}>
              <button className="button" onClick={this.props.closeModal}>
                Okay
              </button>
            </div>
          </div>
        );
      }
    } catch (e) {
      console.log(response);
      console.warn(`Error posting comment:`, e);
    }
    if (this.props.reloadComments) {
      this.props.reloadComments();
    }
    this.setState({ submitting: false });
    return false;
  }

  async handlePreview(event) {
    event.preventDefault();
    let formElement = this.commentSubmitForm.current;
    const formEntries = new FormData(formElement).entries();
    const formJsonData = Object.assign(
      ...Array.from(formEntries, ([x, y]) => ({ [x]: y }))
    );

    this.props.showModal(
      <div className="modal-text">
        <div>
          <CommentDisplay
            key={"pizza"}
            referenceId={"pizza"}
            comment={formJsonData.comment}
            //website={formJsonData.website}
            name={formJsonData.name}
            postId={window.location.pathname.replace(/^\/|\/$/g, "")}
            emailHash={
              "3afadc80217f707a5278e47eddc7d3746bfe03bc6cb0b580f1665a3c83fcd779"
            }
            padding={0}
            timestamp={formJsonData.timestamp}
            admin={false}
            showModal={function () {}}
            closeModal={function () {}}
            reloadComments={function () {}}
          ></CommentDisplay>
        </div>
        <div style={{ textAlign: "center", paddingTop: "7px" }}>
          <button className="button" onClick={this.props.closeModal}>
            Close Preview
          </button>
        </div>
      </div>
    );
  }

  componentDidMount() {}

  render() {
    let submitButtonContent = this.state.submitting ? (
      <div
        style={{
          transform: `scale(0.5) translate(5px, 29px)`,
          margin: "auto",
          width: "58%",
          marginTop: "-30px",
        }}
      >
        <Spinner inverted={true}></Spinner>
      </div>
    ) : (
      <div>Submit</div>
    );
    return (
      <form
        //action="https://us-central1-decisive-design-307818.cloudfunctions.net/post-comment"
        //method="post"
        onSubmit={this.handleSubmit.bind(this)}
        ref={this.commentSubmitForm}
      >
        <input
          type="hidden"
          id="post-id"
          name="postId"
          value={this.props.postId}
          readOnly={this.state.submitting}
          className={this.state.submitting ? "disabled" : undefined}
        />
        <input
          type="hidden"
          id="reply-to"
          name="replyTo"
          value={this.props.replyTo}
          readOnly={this.state.submitting}
          className={this.state.submitting ? "disabled" : undefined}
        />
        <label>
          <input
            placeholder="Name"
            type="text"
            name="name"
            readOnly={this.state.submitting}
            className={this.state.submitting ? "disabled" : undefined}
            style={{ marginBottom: "3px" }}
          />
        </label>
        <br />
        <label>
          <input
            placeholder="Email (will not be shown)"
            type="email"
            name="email"
            readOnly={this.state.submitting}
            className={this.state.submitting ? "disabled" : undefined}
            style={{ marginBottom: "3px" }}
          />
        </label>
        <br />
        {/*
        <label>
          <input
            placeholder="Website (optional)"
            type="text"
            name="website"
            readOnly={this.state.submitting}
            className={this.state.submitting ? "disabled" : undefined}
          />
        </label>
        <br />
        */}
        <label>
          <textarea
            style={{ width: "90%" }}
            placeholder={`Comment - markdown allowed${
              this.props.replyTo ? "" : "" // 75 char minimum used to be here.
            }`}
            cols="50"
            rows="15"
            tabIndex="101"
            data-min-length=""
            name="comment"
            readOnly={this.state.submitting}
            className={this.state.submitting ? "disabled" : undefined}
            minLength={this.props.replyTo ? 0 : 0} // 75 char minimum used to be here.
          ></textarea>
        </label>
        <br />

        <div>
          <button
            className="button"
            style={{ width: "90px", height: "48px", marginLeft: "4px" }}
            disabled={this.state.submitting}
          >
            {submitButtonContent}
          </button>
          <button
            className="button-lite"
            style={{ width: "90px", height: "48px", marginLeft: "4px" }}
            onClick={this.handlePreview.bind(this)}
          >
            <div>Preview</div>
          </button>
        </div>
      </form>
    );
  }
}
export default CommentSubmit;
