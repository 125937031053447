import React from "react";

import { Component } from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";

import Utils from "../helpers/utils";

const COLORS = [
  "red",
  "blue",
  "green",
  "orange",
  "purple",
  "yellow",
  "black",
  "gray",
];
const SHAPES = [3, 4, 5, 6, 7, 8, 9, 100];
const X_MAX = 50;
const Y_MAX = 50;
const Y_OFFSET = 14;
const X_OFFSET = 14;

class CommentDisplay extends Component {
  constructor(props) {
    super(props);
    this.commentDeleteForm = React.createRef();
    this.state = { admin: false };
  }

  drawShape(ctx, color, distance, sides, originX, originY) {
    ctx.beginPath();
    for (let s = 0; s < sides; s++) {
      let angle = (2 * Math.PI) / sides;
      let x = distance * Math.sin(s * angle);
      let y = distance * Math.cos(s * angle);
      ctx.lineTo(x + originX, y + originY);
    }
    ctx.fillStyle = color;
    ctx.fill();
  }

  clipShape(ctx, distance, sides, originX, originY) {
    ctx.beginPath();
    for (let s = 0; s < sides; s++) {
      let angle = (2 * Math.PI) / sides;
      let x = distance * Math.sin(s * angle);
      let y = distance * Math.cos(s * angle);
      ctx.lineTo(x + originX, y + originY);
    }
    ctx.clip();
  }

  async getTriangle(canvas, hexString, size) {
    let ctx = canvas.getContext("2d");
    ctx.save();
    this.clipShape(ctx, size, 3, X_MAX / 2, Y_MAX / 2);

    for (let shapeNumber = 0; shapeNumber < 32; shapeNumber++) {
      const USED_BITS = 16;
      const shift = 1 * shapeNumber;

      let hash = Utils.hexToBinary(hexString);
      let color = COLORS[parseInt(hash.substring(shift + 0, shift + 3), 2)];
      let shape = SHAPES[parseInt(hash.substring(shift + 4, shift + 7), 2)];
      let xLoc =
        (Math.floor(parseInt(hash.substring(shift + 8, shift + 11), 2)) *
          X_MAX) /
        7;
      let yLoc =
        (Math.floor(parseInt(hash.substring(shift + 12, shift + 15), 2)) *
          Y_MAX) /
        7;

      this.drawShape(ctx, color, 10, shape, xLoc, yLoc);
    }
    ctx.restore();

    // Save tri as image
    let imgDataUrl = canvas.toDataURL("image/png", 1);
    let img = new Image();
    await new Promise(r => (img.onload = r), (img.src = imgDataUrl));

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    return img;
  }

  drawTriangle(ctx, img, x, y, angle, flip) {
    ctx.save();

    // Mirror if requested
    if (flip) {
      ctx.scale(1, -1);
      ctx.translate(0, -Y_MAX);
      y = -y;
    }

    // Draw at location
    ctx.translate(x, y);

    // Rotate at origin
    let mod = -2;
    ctx.translate(-X_MAX / mod, -Y_MAX / mod);
    ctx.rotate(angle);
    ctx.translate(X_MAX / mod, Y_MAX / mod);

    // Do the drawing!
    ctx.drawImage(img, 0, 0);

    // Leave everything like we found it
    ctx.restore();
  }

  handleDeletePress(commentId) {
    return function () {
      this.props.showModal(
        <div className="modal-text">
          <div>
            <b>🔒 Please enter the admin password: </b>
          </div>
          <form
            onSubmit={this.handleSubmit.bind(this)}
            ref={this.commentDeleteForm}
          >
            <input
              type="hidden"
              name="postId"
              value={this.props.postId}
              readOnly={false}
              //className={this.state.submitting ? "disabled" : undefined}
            />
            <input
              type="hidden"
              name="commentId"
              value={commentId}
              readOnly={false}
              //className={this.state.submitting ? "disabled" : undefined}
            />
            <input name="password" type="password"></input>
            <div style={{ textAlign: "center", paddingTop: "7px" }}>
              <button className="button" onClick={this.props.closeModal}>
                Submit
              </button>
            </div>
          </form>
        </div>
      );
    }.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    let formElement = this.commentDeleteForm.current;
    const formEntries = new FormData(formElement).entries();
    const formJsonData = Object.assign(
      ...Array.from(formEntries, ([x, y]) => ({ [x]: y }))
    );
    console.log("Deleting", formJsonData);
    let response = {};
    let reply = [];
    try {
      this.setState({ submitting: true });
      response = await fetch(
        "https://us-central1-decisive-design-307818.cloudfunctions.net/delete-comment",
        {
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formJsonData),
        }
      );
      reply = await response.json();
      console.log(reply);

      // Only reset if successful
      if (response.status === 200) {
        this.props.showModal(
          <div className="modal-text">
            <div>
              <b>✅ The comment was successfully deleted!</b>
            </div>
            <div style={{ textAlign: "center", paddingTop: "7px" }}>
              <button className="button" onClick={this.props.closeModal}>
                Okay
              </button>
            </div>
          </div>
        );
      } else {
        this.props.showModal(
          <div className="modal-text">
            <div>
              <b>❌ There was an error while attempting delete the comment: </b>
              {reply.message}
            </div>
            <div style={{ textAlign: "center", paddingTop: "7px" }}>
              <button className="button" onClick={this.props.closeModal}>
                Okay
              </button>
            </div>
          </div>
        );
      }
    } catch (e) {
      console.log(response);
      console.warn(`Error deleting comment:`, e);
    }
    if (this.props.reloadComments) {
      this.props.reloadComments();
    }
    //this.setState({ submitting: false });
    return false;
  }

  async componentDidMount() {
    let canvas = document.getElementById(`canvas-${this.props.referenceId}`);
    if (canvas && canvas.getContext) {
      const size = 22;
      let base64String = this.props.emailHash; //(Math.random() + 1).toString(36).substring(7);
      let tri = await this.getTriangle(
        canvas,
        Utils.base64ToHex(base64String),
        size
      );

      let ctx = canvas.getContext("2d");
      ctx.translate(X_OFFSET, Y_OFFSET);

      const P2 = 2 * Math.PI;

      // https://docs.google.com/spreadsheets/d/1MDUV0UjwIbVSi0c-AKLsLJ3QY4aaoHlQI0AUA2YqhhU/edit#gid=0
      const A = Math.cos(1.0472) * size;
      const B = Math.sin(1.0472) * size;
      const C = A * 3; // C

      // Zoom out
      ctx.scale(0.5, 0.5);

      // Level 0
      this.drawTriangle(ctx, tri, 0, 0, P2 * (0 / 3), false);

      // Level 1
      this.drawTriangle(ctx, tri, 0, -C + A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, B, A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, -B, A, P2 * (2 / 3), true);

      // Level 3
      this.drawTriangle(ctx, tri, B, -C, P2 * (1 / 3), false);
      this.drawTriangle(ctx, tri, -B, -C, P2 * (2 / 3), false);

      this.drawTriangle(ctx, tri, -B * 2, 0, P2 * (1 / 3), false);
      this.drawTriangle(ctx, tri, B * 2, 0, P2 * (2 / 3), false);

      this.drawTriangle(ctx, tri, -B, C, P2 * (2 / 3), false);
      this.drawTriangle(ctx, tri, B, C, P2 * (1 / 3), false);

      // Level 4
      this.drawTriangle(ctx, tri, B, -C * 2 + A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, -B, -C * 2 + A, P2 * (2 / 3), true);

      this.drawTriangle(ctx, tri, B * 2, -C + A, P2 * (2 / 3), true);
      this.drawTriangle(ctx, tri, -B * 2, -C + A, P2 * (1 / 3), true);

      this.drawTriangle(ctx, tri, B * 3, A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, -B * 3, A, P2 * (0 / 3), true);

      this.drawTriangle(ctx, tri, -B * 2, C + A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, 0, C + A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, B * 2, C + A, P2 * (2 / 3), true);

      // Level 5
      this.drawTriangle(ctx, tri, -B * 2, -C * 2, P2 * (1 / 3), false);
      this.drawTriangle(ctx, tri, 0, -C * 2, P2 * (0 / 3), false);
      this.drawTriangle(ctx, tri, B * 2, -C * 2, P2 * (2 / 3), false);

      this.drawTriangle(ctx, tri, -B * 3, -C, P2 * (0 / 3), false);
      this.drawTriangle(ctx, tri, B * 3, -C, P2 * (0 / 3), false);

      this.drawTriangle(ctx, tri, -B * 4, 0, P2 * (2 / 3), false);
      this.drawTriangle(ctx, tri, B * 4, 0, P2 * (1 / 3), false);

      this.drawTriangle(ctx, tri, -B * 3, C, P2 * (0 / 3), false);
      this.drawTriangle(ctx, tri, B * 3, C, P2 * (0 / 3), false);

      this.drawTriangle(ctx, tri, -B * 2, C * 2, P2 * (1 / 3), false);
      this.drawTriangle(ctx, tri, 0, C * 2, P2 * (0 / 3), false);
      this.drawTriangle(ctx, tri, B * 2, C * 2, P2 * (2 / 3), false);

      // Level 6
      this.drawTriangle(ctx, tri, -B * 2, -C * 3 + A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, 0, -C * 3 + A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, B * 2, -C * 3 + A, P2 * (2 / 3), true);

      this.drawTriangle(ctx, tri, -B * 3, -C * 2 + A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, B * 3, -C * 2 + A, P2 * (0 / 3), true);

      this.drawTriangle(ctx, tri, -B * 4, -C + A, P2 * (2 / 3), true);
      this.drawTriangle(ctx, tri, B * 4, -C + A, P2 * (1 / 3), true);

      this.drawTriangle(ctx, tri, -B * 5, A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, B * 5, A, P2 * (2 / 3), true);

      this.drawTriangle(ctx, tri, -B * 4, C + A, P2 * (2 / 3), true);
      this.drawTriangle(ctx, tri, B * 4, C + A, P2 * (1 / 3), true);

      this.drawTriangle(ctx, tri, -B * 3, C * 2 + A, P2 * (0 / 3), true);
      this.drawTriangle(ctx, tri, -B, C * 2 + A, P2 * (2 / 3), true);
      this.drawTriangle(ctx, tri, B, C * 2 + A, P2 * (1 / 3), true);
      this.drawTriangle(ctx, tri, B * 3, C * 2 + A, P2 * (0 / 3), true);
    }
  }

  render() {
    /*
    const websiteLink = this.props.website ? (
      <a rel="nofollow noreferrer" href={this.props.website}>
        @ {this.props.website}
      </a>
    ) : null;
    */
    let deleteButton = (
      <button
        style={{ marginLeft: "3px" }}
        className="button"
        onClick={this.handleDeletePress(this.props.referenceId)}
      >
        Delete
      </button>
    );
    return (
      <div
        className="comment"
        style={{
          marginLeft: this.props.padding,
        }}
      >
        <div style={{ display: "flex" }}>
          <canvas
            id={`canvas-${this.props.referenceId}`}
            width={Y_MAX}
            height={X_MAX}
            style={{
              //border: "black",
              //borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "3px",
              marginRight: "4px",
            }}
          ></canvas>
          <div className="comment-title-container">
            <div className="comment-name-web">
              <b>{this.props.name}</b>
              {/* {websiteLink}*/}
            </div>
            <div className="comment-date">
              {Utils.secondsToFormattedTimeString(
                Math.round((Date.now() - this.props.timestamp) / 1000),
                3
              )}{" "}
              ago
            </div>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              marked.parse(this.props.comment ? this.props.comment : "")
            ),
          }}
        ></div>
        <div style={{ textAlign: "right", marginTop: "-12px" }}>
          <button
            onClick={this.props.onReplyClick}
            style={{ color: "darkblue" }}
          >
            <i>Reply</i>
          </button>
          {this.props.admin ? deleteButton : null}
        </div>
      </div>
    );
  }
}
export default CommentDisplay;
